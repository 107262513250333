// import "./styles.css";
/* eslint-disable */
import { LineChart, AreaChart } from "react-chartkick";
import "chart.js";
import { useEffect, useState, useCallback } from "react";
import { useParams } from "react-router-dom";
// import Graph from './Graph';
import "flowbite";

import "react-datepicker/dist/react-datepicker.css";
import { useOrganizationId } from "../hooks/useOrganizationId";
import CurrencyInput from "react-currency-input-field";

export default function SubscriptionCreationView(props) {
  const [statisticsMode, setStatisticsMode] = useState(0);
  const [productMode, setProductMode] = useState(0);
  let { id } = useParams();
  let { organizationId } = useOrganizationId();

  const [products, setProducts] = useState([]);
  const [isLoading, setIsLoading] = useState(0);
  function handleExpand() {
    setIsLoading(true);
    let level = {
      levelName: "",
      upper: "",
      lower: "",
      price: "",
    };

    let p = products;

    console.log("products", p);
    p.push(level);
    // p[products.size] = level;

    setProducts(p);
    setIsLoading(isLoading + 1);
  }

  function handleSubmit() {
    console.log(products);
    let m = {};
    let a = [];
    for (let i = 0; i < products.length; i++) {
      console.log(i + "-levelName");
      const levelName = document.getElementById(i + "-levelName");
      const lower = document.getElementById(i + "-lower");
      const upper = document.getElementById(i + "-upper");
      const price = document.getElementById(i + "-price");
      // console.log(el.value);
      let level = {
        levelName: levelName.value,
        upper: upper.value,
        lower: lower.value,
        price: price.value.replace("$", "").replace(",", ""),
      };
      console.log("level", level);
      m[i] = level;
      a.push(level);
    }
    setProducts(a);
    console.log("m", m);
    console.log("a", a);

    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    const raw = JSON.stringify({
      organizationId: organizationId,
      groupId: id,
      groupName: productGroup.name,
      variable: productGroup.variable,
      billingCycle: productGroup.billingCycle,
      levels: JSON.stringify(a),
    });

    console.log("raw", {
      organizationId: organizationId,
      groupId: id,
      groupName: productGroup.name,
      variable: productGroup.variable,
      billingCycle: productGroup.billingCycle,
      levels: a,
    });

    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      // redirect: "follow",
    };

    fetch(
      // "http://application.eba-i3r4awwg.us-east-2.elasticbeanstalk.com/"
      `${process.env.REACT_APP_API_ENDPOINT}` + "/skews/create",
      requestOptions
    )
      .then((response) => response.text())
      .then((result) => {
        console.log(result);
      })
      .catch((error) => console.log("error", error));
  }

  useEffect(() => {
    console.log("products", products);

    try {
      fetch(
        `${process.env.REACT_APP_API_ENDPOINT}` + `/skews/get/${id}`
      )
        .then((res) => res.json())
        .then((response) => {
          let stateObj = [];
          console.log("dasdsa", JSON.stringify(response));
          setProducts(response["data"]);
        });
    } catch (err) {
      console.error(err);
    }
  }, []);

  const [productGroup, setProductGroup] = useState([]);

  useEffect(() => {
    console.log("products", products);

    try {
      fetch(
        `${process.env.REACT_APP_API_ENDPOINT}` + `/products/get-by-id/${id}`
      )
        .then((res) => res.json())
        .then((response) => {
          let stateObj = [];
          console.log("123456", JSON.stringify(response));
          setProductGroup(response["data"]);
        });
    } catch (err) {
      console.error(err);
    }
  }, []);

  useEffect(() => {
    console.log("products", products);
  }, [isLoading]);

  const capitalize = (string) => {
    return string ? string.charAt(0).toUpperCase() + string.slice(1) : "";
  };

  return (
    <>
      <div className="w-full overflow-scroll h-screen pb-40">
        <div className="flex flex-wrap justify-between items-center mx-auto">
          <h1 className="text-3xl font-bold mt-12 ml-12 dark:text-white">Create Tiers</h1>
        </div>

        <nav
          class="flex px-5 py-3 text-gray-700 border border-gray-200 rounded-lg bg-gray-50 dark:bg-gray-800 dark:border-gray-700 w-fit ml-12 mt-6"
          aria-label="Breadcrumb"
        >
          <ol class="inline-flex items-center space-x-1 md:space-x-3">
            <li class="inline-flex items-center">
              <a

                href="/products"
                class="inline-flex items-center text-sm font-medium text-gray-700 hover:text-gray-900 dark:text-gray-400 dark:hover:text-white"
              >
                Products
              </a>
            </li>
            <li>
              <div class="flex items-center">
                <svg
                  class="w-6 h-6 text-gray-400"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fill-rule="evenodd"
                    d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                    clip-rule="evenodd"
                  ></path>
                </svg>
                <a
                  href="/products"
                  class="ml-1 text-sm font-medium text-gray-700 hover:text-gray-900 md:ml-2 dark:text-gray-400 dark:hover:text-white"
                >
                  Tiers

                </a>
              </div>
            </li>
            <li aria-current="page">
              <div class="flex items-center">
                <svg
                  class="w-6 h-6 text-gray-400"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fill-rule="evenodd"
                    d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                    clip-rule="evenodd"
                  ></path>
                </svg>
                <span class="ml-1 text-sm font-medium text-gray-500 md:ml-2 dark:text-gray-400">
                  #{id.substring(24)}
                </span>
              </div>
            </li>
          </ol>
        </nav>
        <h2 className="text-2xl font-bold mt-12 ml-12 dark:text-white">
          {productGroup.name}
        </h2>

        <div className="flex flex-row divide-x mt-4">
          <div>
            <span class="ml-12 mr-4 bg-blue-100 text-blue-800 text-sm font-medium inline-flex items-center px-2.5 py-0.5 rounded dark:bg-blue-200 dark:text-blue-800">
              <svg
                aria-hidden="true"
                class="mr-1 w-3 h-3"
                fill="currentColor"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fill-rule="evenodd"
                  d="M10 18a8 8 0 100-16 8 8 0 000 16zm1-12a1 1 0 10-2 0v4a1 1 0 00.293.707l2.828 2.829a1 1 0 101.415-1.415L11 9.586V6z"
                  clip-rule="evenodd"
                ></path>
              </svg>
              {capitalize(productGroup.billingCycle)}
            </span>
          </div>
          <div>
            <span class="ml-4 bg-blue-100 text-blue-800 text-sm font-medium inline-flex items-center px-2.5 py-0.5 rounded dark:bg-blue-200 dark:text-blue-800">
              Variable: {capitalize(productGroup.variable)}
            </span>
          </div>
        </div>

        <div className="flex flex-row justify-evenly w-full justify-center mt-6 w-full">
          <div class="overflow-x-auto relative w-full ml-12 mr-12">
            <table class="w-full text-sm text-left text-gray-500 dark:text-gray-400">
              <thead class="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                <tr>
                  <th scope="col" class="py-3 px-6">
                    Level Name
                  </th>
                  <th scope="col" class="py-3 px-6">
                    Lower
                  </th>
                  <th scope="col" class="py-3 px-6">
                    Upper
                  </th>
                  <th scope="col" class="py-3 px-6">
                    Price
                  </th>
                  <th scope="col" class="py-3 px-6">
                    Action
                  </th>
                </tr>
              </thead>
              <tbody>
                {products.map((product, index) => {
                  console.log(index);
                  return (
                    <tr class="bg-white border-b dark:bg-gray-800 dark:border-gray-700">
                      <th
                        scope="row"
                        class="py-4 px-6 font-medium text-gray-900 whitespace-nowrap dark:text-white"
                      >
                        <input
                          id={`${index}-levelName`}
                          name="input-name"
                          placeholder="Level Name"
                          decimalsLimit={2}
                          autoComplete="off"
                          prefix="$"
                          defaultValue={product.levelName}
                          // onChange={e => setProductName(e.target.value)}
                          value={props.name}
                          className=" bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                        />
                      </th>
                      <td class="py-4 px-6">
                        <input
                          id={`${index}-lower`}
                          name="input-name"
                          placeholder="Lower"
                          decimalsLimit={2}
                          autoComplete="off"
                          prefix="$"
                          defaultValue={product.lower}
                          type={"number"}
                          // onChange={e => setProductName(e.target.value)}
                          value={props.name}
                          className=" bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                        />
                      </td>
                      <td class="py-4 px-6">
                        <input
                          id={`${index}-upper`}
                          name="input-name"
                          placeholder="Upper"
                          decimalsLimit={2}
                          autoComplete="off"
                          prefix="$"
                          type={"number"}
                          defaultValue={product.upper}
                          // onChange={e => setProductName(e.target.value)}
                          value={props.name}
                          className=" bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                        />
                      </td>
                      <td class="py-4 px-6">
                        <CurrencyInput
                          id={`${index}-price`}
                          name="input-name"
                          placeholder="Price"
                          decimalsLimit={2}
                          autoComplete="off"
                          prefix="$"
                          defaultValue={product.price}
                          // onChange={e => setProductName(e.target.value)}
                          value={props.name}
                          className=" bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                        />
                      </td>
                      <td class="py-4 px-6">

                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>





            <button
              onClick={handleExpand}
              className="mt-8 py-2 px-4 mr-4  h-max text-sm font-medium text-gray-900 bg-white rounded-lg border border-gray-200 hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-2 focus:ring-blue-700 focus:text-blue-700 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700"
            >
              Add Level
            </button>

            <button
              onClick={handleSubmit}
              className="mt-8 py-2 px-4 mr-4  h-max text-sm font-medium text-gray-900 bg-white rounded-lg border border-gray-200 hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-2 focus:ring-blue-700 focus:text-blue-700 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700"
            >
              Save
            </button>
          </div>






        </div>

        <div>
          <h1>Shipping Data:</h1>

        </div>
      </div>
      {/* </div> */}
    </>
  );
}
