
import "./App.css";
import NavBar from "./NavBar";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { Auth0Provider } from "@auth0/auth0-react";
import { PrivateRoute } from "./components/PrivateRoute";
import { useState } from "react";
import {
  Dashboard,
  Customers,
  Transactions,
  CreateTransaction,
  CreateCustomer,
  TransactionDetail,
  CustomerDetail,
  Products,
  Integrations,
  Settings,
  Onboarding,
  OnboardingIntegrations,
  Disputes,
  BillingDashboard,
  Checkout,
  Workflow,
  BillingHistoryDashboard,
  ProductsView,
  SellerProfile,
  Sellers
} from "./Loadables";
import SubscriptionCreationView from "./Products/SubscriptionCreationView";
import InvoiceTemplate from "./invoice/InvoiceTemplate";
import useSWR from "swr";
import { fetcher } from "./utils/fetcher";
import NavigationBar from "./NavigationBar";

function App() {
  const [showNav, setShowNav] = useState(true);
  const onRedirectCallback = (appState, user) => {
    fetch(`${process.env.REACT_APP_API_ENDPOINT}/team/get-organization/${user.email}`)
      .then((res) => res.json())
      .then((response) => {
        // alert(JSON.stringify(response));
        const hasOnboarded = response.data.hasOnboarded;
        if (!hasOnboarded) {
          window.location.href = '/onboarding'
        } else {
        }
      })

  }
  console.log(window.location);
  return (
    <Auth0Provider
      domain="auth.bluehillpayments.com"
      clientId="qLK3bDJyckt2eJdJ8WmbUHfRlmgACjO5"
      redirectUri={window.location.origin}
      useRefreshTokens={true}
      cacheLocation="localstorage"
      onRedirectCallback={onRedirectCallback}

    >

      {/* <div className="flex flex-row"> */}
      {/* <NavBar /> */}
      <NavigationBar />
      <Router>
        <Routes>
          <Route path="/" element={<PrivateRoute component={Dashboard} />} />
          <Route
            path="/customers"
            element={<PrivateRoute component={Customers} />}
          />
          <Route
            path="/products-view/:id"
            element={<PrivateRoute component={ProductsView} />}
          />
          <Route
            path="/transactions"
            element={<PrivateRoute component={Transactions} />}
          />
          <Route
            path="/create-transaction"
            element={<PrivateRoute component={CreateTransaction} />}
          />
          <Route
            path="/create-customer"
            element={<PrivateRoute component={CreateCustomer} />}
          />
          <Route
            path="/transactions/:id"
            element={<PrivateRoute component={TransactionDetail} />}
          />
          <Route
            path="customers/:id"
            element={<PrivateRoute component={CustomerDetail} />}
          />
          <Route
            path="customers/:id/:page"
            element={<PrivateRoute component={CustomerDetail} />}
          />

          <Route
            path="/flow"
            element={<PrivateRoute component={Workflow} />}
          />

          <Route
            path="/products"
            element={<PrivateRoute component={Products} />}
          />
          <Route
            path="/integrations"
            element={<PrivateRoute component={Integrations} />}
          />
          <Route
            path="/settings"
            element={<PrivateRoute component={Settings} />}
          />

          <Route
            path="/onboarding"
            element={<PrivateRoute component={Onboarding} />}
          />

          <Route
            path="/disputes"
            element={<PrivateRoute component={Disputes} />}
          />

          <Route
            path="/billing"
            element={<PrivateRoute component={BillingDashboard} />}
          />

          <Route
            path="/billing-history"
            element={<PrivateRoute component={BillingHistoryDashboard} />}
          />

          <Route
            path="/sellers/:id"
            element={<PrivateRoute component={SellerProfile} />}
          />

          <Route
            path='sellers'
            element={<PrivateRoute component={Sellers} />}
          />


          <Route
            path="/products/:id"
            element={<PrivateRoute component={SubscriptionCreationView} />}
          />
          <Route
            path="/integrations/onboarding/:gateway"
            element={<PrivateRoute component={OnboardingIntegrations} />}
          />

          <Route
            path="/invoices"
            element={<PrivateRoute component={InvoiceTemplate} />}
          />

          <Route
            path="/checkout"
            element={<PrivateRoute component={Checkout} />}
          />

          <Route
            path="/integrations/onboarding/:gateway"
            element={<PrivateRoute component={OnboardingIntegrations} />}
          />
        </Routes >
      </Router >
      {/* </div> */}
    </Auth0Provider >
  );
}

export default App;
