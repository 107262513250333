// import "./styles.css";
import { LineChart, AreaChart, PieChart, BarChart, ColumnChart } from "react-chartkick";
import "chart.js";
import { useEffect, useState } from "react";
import { fetcher } from "../utils/fetcher";
import useSWR from "swr";
import Spinner from "../components/Spinner";
import { useOrganizationId } from "../hooks/useOrganizationId";
// import Graph from './Graph';

export default function ProcessorGraph() {
  const [transactions, setTransactions] = useState([]);
  const { organizationId } = useOrganizationId();
  const [offset, setOffset] = useState(0);
  const [limit, setLimit] = useState(6);
  const { data, error } = useSWR(
    () =>
      `${process.env.REACT_APP_API_ENDPOINT}/dashboard/v2/get-processor-breakdown/${organizationId}`,
    fetcher
  );
  if (error) return <Spinner center />;
  if (!data) return <Spinner center />;
  return (
    <div className="relative overflow-hidden rounded-lg bg-white dark:bg-gray-700 shadow mt-12 pb-12">

      <div className="inset-x-0 top-0 bg-gray-50 dark:bg-gray-800 px-4 py-4 sm:px-6 flex flex-row">
        <div className="text-lg dark:text-white w-full">


          <p className="text-2xl font-semibold text-gray-900 dark:text-white">Processors </p>
          <p className="truncate text-sm font-medium text-gray-500 dark:text-gray-200 w-full">See where transactions are routed</p>

        </div>

      </div>

      <div className="w-full flex justify-center mt-12 h-max">
        <div className="w-full pl-12 pr-12">
          <ColumnChart data={data.data} colors={["#3F83F8"]} />
        </div>
      </div>
    </div>
  );
}
