// import "./styles.css";
import { LineChart, AreaChart, PieChart } from "react-chartkick";
import "chart.js";
import { useEffect, useState } from "react";
import { fetcher } from "../utils/fetcher";
import useSWR from "swr";
import { useOrganizationId } from "../hooks/useOrganizationId";
import Spinner from "../components/Spinner";
// import Graph from './Graph';

export default function CardNetworkGraph(props) {
  const [transactions, setTransactions] = useState([]);
  const [offset, setOffset] = useState(0);
  const [limit, setLimit] = useState(6);
  const { organizationId } = useOrganizationId();
  const { data, error } = useSWR(
    organizationId
      ? `${process.env.REACT_APP_API_ENDPOINT}/dashboard/v2/get-card-network/${organizationId}`
      : null,
    fetcher
  );

  if (error) return <div>{error}</div>;
  if (!data) return <Spinner table />;

  return (
    <div className="relative overflow-hidden rounded-lg bg-white dark:bg-gray-700 shadow mt-12 pb-12 w-full">
      {console.log('endpoint', `${process.env.REACT_APP_API_ENDPOINT}/dashboard/v2/get-card-network/${organizationId}`)}
      <div className="inset-x-0 top-0 bg-gray-50 dark:bg-gray-800 px-4 py-4 sm:px-6 flex flex-row">
        <div className="text-lg dark:text-white w-full">


          <p className="text-2xl font-semibold text-gray-900 dark:text-white">Payment Methods </p>
          <p className="truncate text-sm font-medium text-gray-500 dark:text-gray-200 w-full">See which payment methods your customers are using</p>

        </div>

      </div>


      {/* <h1 className="text-2xl font-medium dark:text-gray-200 mt-4">Network Breakdown</h1> */}
      < div className="w-full flex flex-col justify-center mt-12" >

        <div className="w-full">
          <PieChart data={data.data} colors={["#3b82f6cc", "#3F83F8"]} />
        </div>


      </div >
    </div >
  );
}
