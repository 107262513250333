// import "./styles.css";
import { LineChart, AreaChart, ColumnChart } from "react-chartkick";
import "chart.js";
import { useEffect, useState } from "react";
import { useOrganizationId } from "../hooks/useOrganizationId";
// import Graph from './Graph';

export default function Graph() {
  const [transactions, setTransactions] = useState([]);
  const [offset, setOffset] = useState(0);
  const [limit, setLimit] = useState(6);
  const { organizationId } = useOrganizationId();

  const [total, setTotal] = useState(0);
  useEffect(() => {
    if (organizationId) {

      // console÷
      fetch(
        `${process.env.REACT_APP_API_ENDPOINT}/dashboard/v2/get-transactions/${organizationId}`
      )
        .then((response) => response.json())
        .then(
          (data) => {
            setTransactions(data["data"])

            let t = 0;
            for (let d in data["data"]) {
              console.log('d ', data['data'][d][1])
              t += data['data'][d][1]
            }
            setTotal(t)
            console.log('dataBH ', data["data"])
          }
          // console.log(data)


          // }
        );
    }
  }, [offset, limit, organizationId]);


  useEffect(() => {

  }, [transactions.length, total])
  var formatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",

    // These options are needed to round to whole numbers if that's what you want.
    //minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
    //maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
  });
  return (
    // <div className={"bg-white border border-gray-700 px-2 sm:px-4 rounded-xl dark:bg-gray-800 ml-12 mr-12 mt-4 mb-24"}>
    <div className="relative overflow-hidden rounded-lg bg-white dark:bg-gray-700 shadow mr-12 ml-12">


      <div className="inset-x-0 top-0 bg-gray-50 dark:bg-gray-800 px-4 py-4 sm:px-6 flex flex-row">
        <div className="text-lg dark:text-white w-1/2">
          <p className="truncate text-sm font-medium text-gray-500 dark:text-gray-200">Total Volume: </p>

          <p className="text-2xl font-semibold text-gray-900 dark:text-white">{formatter.format(total)}</p>

        </div>
        <div className="text-lg dark:text-white w-1/2 flex justify-end ">
          <div>

            <div class="text-sm font-medium text-center text-gray-500 border-b border-gray-200 dark:text-gray-400 dark:border-gray-700">
              <ul class="flex flex-wrap -mb-px">
                <li class="mr-2">
                  <a href="#" class="inline-block p-4 border-b-2 border-transparent rounded-t-lg hover:text-gray-600 hover:border-gray-300 dark:hover:text-gray-300">7 Days</a>
                </li>
                <li class="mr-2">
                  <a href="#" class="inline-block p-4 border-b-2 border-transparent rounded-t-lg hover:text-gray-600 hover:border-gray-300 dark:hover:text-gray-300">30 Days</a>
                </li>
                <li class="mr-2">
                  <a href="#" class="inline-block p-4 border-b-2 border-transparent rounded-t-lg hover:text-gray-600 hover:border-gray-300 dark:hover:text-gray-300">1 Year</a>
                </li>
                <li class="mr-2">
                  <a href="#" class="inline-block p-4 text-blue-600 border-b-2 border-blue-600 rounded-t-lg active dark:text-blue-500 dark:border-blue-500" aria-current="page">All</a>
                </li>
              </ul>
            </div>

          </div>
        </div>
      </div>
      <div className="w-full flex mt-12 pr-12">
        {/* <div className="w-full pl-12 pr-12"> */}
        {console.log('dataBH graph ', transactions)}
        {transactions.length > 0 ? <ColumnChart data={transactions} colors={["#3F83F8"]} library={{
          legend: {
            labels: {
              fontColor: "#ffffff"
            }
          },
          scales: {
            xAxes: [
              {
                ticks: { fontColor: "#ffffff" },
                gridLines: { drawBorder: true, color: "#ffffff" }
              }
            ],
            yAxes: [
              {
                ticks: { fontColor: "#ffffff" },
                gridLines: { drawBorder: true, color: "#ffffff0" }
              }
            ]
          }
        }} /> : <></>}
        {/* </div> */}
      </div>
      {/* <Timeline initialLimit={5} showDescription={true} /> */}
    </div>
    // </div>
  );
}
