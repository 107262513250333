import { StyleSheet, Text, View, Image } from '@react-pdf/renderer'

const styles = StyleSheet.create({
  container: {
    borderTop: '1px solid #999',
    display: 'flex',
    flexDirection: 'row',
    fontSize: 10,
    marginTop: 32,
    paddingTop: 4,
  },
  left: {
    flex: 1,
  },
  right: {
    fontStyle: 'italic',
  },
})

function Footer() {
  return (
    <View style={styles.container}>
      <Image src={'./TextBlueHillTransparent.png'} style={{ height: 30, objectFit: 'contain' }} />
      {/* <Text style={styles.left}>BlueHill Payments, Inc.</Text> */}
      {/* <Text style={styles.right}> */}
      {/* {`Seal Coating  ·  Striping  ·  Blacktop Paving and Repairs  ·  Insured and Bonded`} */}
      {/* </Text> */}
    </View>
  )
}

export default Footer
