// import logo from "./logo.svg";
import "../App.css";

import { useState } from "react";
import StateSelect from "../StateSelect";
import { useOrganizationId } from "../hooks/useOrganizationId";

function CreateCustomer(props) {
  const [lastName, setLastName] = useState("");
  const [firstName, setFirstName] = useState("");
  const [email, setEmail] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [street, setStreet] = useState("");
  const [town, setTown] = useState("");
  const [zipCode, setZipCode] = useState("");
  const [state, setState] = useState("");
  const [notes, setNotes] = useState("");
  const [company, setCompany] = useState("");

  const { organizationId } = useOrganizationId();
  // const [selected, setSelected] = useState("");

  const [selectedOption, setSelectedOption] = useState(null);

  function handleSubmit(event) {
    const data = {
      organizationId: organizationId,
      lastName: lastName,
      firstName: firstName,
      email: email,
      phoneNumber: phoneNumber,
      street: street,
      town: town,
      zipCode: zipCode,
      state: state,
      notes: notes,
      company: company,
    };

    const requestOptions = {
      method: "POST",
      // headers: { "Content-Type": "application/json" },
      body: JSON.stringify(data),
    };

    try {
      fetch(
        `${process.env.REACT_APP_API_ENDPOINT}/customers/v2/create`,
        requestOptions
      )
        .then((res) => res.json())
        .then((response) => console.log(response))
        .then(() => {
          props.goHome();
        });
    } catch (err) {
      console.error(err);
    }

    event.preventDefault();
  }

  // function passedFunction(e) {
  //   console.log(e)
  // }

  return (
    <div>
      <div className=" border-gray-200 px-2 sm:px-4 py-2.5 rounded-[20px] dark:bg-gray-800 ml-8 mr-8 mt-8 mb-8 h-full">
        <form onSubmit={handleSubmit}>
          <div className="w-1/4" />

          <div className="grid xl:grid-cols-5 xl:gap-6 ml-4 mt-4 auto-cols-max mr-4">
            <div className="relative z-0 mb-6 w-full group">
              <label
                htmlFor="company"
                className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300"
              >
                Organization
              </label>
              <input
                id="company"
                value={company}
                onChange={(e) => setCompany(e.target.value)}
                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                placeholder="Organization"
                required
              />
            </div>
            <div className="relative z-0 mb-6 w-full group">
              <label
                htmlFor="firstName"
                className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300"
              >
                First Name
              </label>
              <input
                id="firstName"
                value={firstName}
                onChange={(e) => setFirstName(e.target.value)}
                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                placeholder="First Name"
                required
              />
            </div>
            <div className="">
              <label
                htmlFor="lastName"
                className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300"
              >
                Last Name
              </label>
              <input
                id="lastName"
                value={lastName}
                onChange={(e) => setLastName(e.target.value)}
                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                placeholder="Last Name"
                required
              />
            </div>
          </div>

          <div className="grid xl:grid-cols-5 xl:gap-6 ml-4 mt-4 auto-cols-max mr-4">
            <div className="w-full">
              <label
                htmlFor="email"
                className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300"
              >
                Email
              </label>
              <input
                id="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                placeholder="Email"
                required
              />
            </div>

            <div className="w-full">
              <label
                htmlFor="phoneNumber"
                className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300"
              >
                Phone Number
              </label>
              <input
                id="phoneNumber"
                type="tel"
                value={phoneNumber}
                onChange={(e) => setPhoneNumber(e.target.value)}
                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                placeholder="Phone Number"
                required
              />
            </div>
          </div>

          <div className="grid xl:grid-cols-4 xl:gap-6 ml-4 mt-8 auto-cols-max">
            <div className="relative z-0 mb-6 w-full group">
              <label
                htmlFor="street"
                className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300"
              >
                Street
              </label>
              <input
                id="street"
                value={street}
                onChange={(e) => setStreet(e.target.value)}
                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                placeholder="Street"
                required
              />
            </div>
            <div className="grid xl:grid-cols-2 xl:gap-6">
              <div className="w-full mr-4">
                <label
                  htmlFor="town"
                  className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                >
                  City/Town
                </label>
                <input
                  id="town"
                  value={town}
                  onChange={(e) => setTown(e.target.value)}
                  className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  placeholder="City/Town"
                  required
                />
              </div>

              <div className="w-full">
                <label
                  htmlFor="zipCode"
                  className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                >
                  Zip Code
                </label>
                <input
                  id="zipCode"
                  value={zipCode}
                  onChange={(e) => setZipCode(e.target.value)}
                  className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  placeholder="Zip Code"
                  required
                />
              </div>
            </div>

            <div className="w-3/4">
              <div>
                <StateSelect valueFunc={setState} />
              </div>
            </div>
          </div>

          <button
            type="submit"
            // href={`/transactions/${transaction.id}`}
            className="mt-12 inline-flex items-center py-2 px-4 text-sm font-medium text-gray-900 bg-white rounded-lg border border-gray-200 hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-2 focus:ring-blue-700 focus:text-blue-700 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700"
          >
            Create Customer
          </button>
        </form>
      </div>
    </div>
  );
}

export default CreateCustomer;
