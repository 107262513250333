// @ts-nocheck

import { useState, useEffect } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import useSWR from "swr";
import { fetcher } from "../utils/fetcher";

export function useOrganizationId() {
  const [orgId, setOrgId] = useState(null);
  const { user, isAuthenticated, isLoading, loginWithRedirect } = useAuth0();
  if (!isAuthenticated) {
    loginWithRedirect();
  }
  // const [globalOrgId, setGlobalOrgId] = useAtom(organizationIdAtom);
  const { data, error } = useSWR(() => `${process.env.REACT_APP_API_ENDPOINT}/team/get-organization/${user.email}`,
    fetcher
  );
  if (!isLoading && data?.data?.hasOnboarded !== undefined && !data?.data?.hasOnboarded) {
    console.log('loading', !isLoading)
    console.log(data)
    console.log(data?.data?.hasOnboarded)
    // window.location.href = '/onboarding'
  } 
  return {
    organizationId: data?.data?.organizationId,
    hasOnboarded: data?.data?.hasOnboarded,
    isLoading: !error && !data,
    isError: error,
  };

  // useEffect(() => {
  //   if (isAuthenticated) {
  //     if (orgId != null) {
  //       return;
  //     } else {
  //       try {
  //         fetch(
  //           `${process.env.REACT_APP_API_ENDPOINT}/team/get-organization/${user.email}`
  //         )
  //           .then((res) => res.json())
  //           .then((response) => {
  //             if (response.status === "failure") {
  //               console.error("could not get organizationId—invalid email");
  //               console.log(
  //                 `${process.env.REACT_APP_API_ENDPOINT}/team/get-organization/${user.email}`
  //               );
  //             } else {
  //               // setGlobalOrgId(response.data.organizationId);
  //               setOrgId(response.data.organizationId);
  //             }
  //           });
  //       } catch (err) {
  //         console.error(err);
  //       }
  //     }
  //   } else {
  //     loginWithRedirect();
  //   }
  // }, [isAuthenticated, orgId, user]);

  // if (orgId == null) return "loading";
  // return { organizationId: orgId };
}
