import "../App.css";

import CreateCustomer from "./CreateCustomer";
import CustomersTable from "./CustomersTable";
import { useState } from "react";
import CustomersHeader from "./CustomersHeader";
import { useAuth0 } from "@auth0/auth0-react";

function Customers(props) {

  const [pageNumber, setPageNumber] = useState(1);
  const [statisticsMode, setStatisticsMode] = useState(0);
  const { user, isAuthenticated, isLoading, getAccessTokenSilently } = useAuth0();

  function goHome() {
    setStatisticsMode(0);
  }

  function getButtonClass(n) {
    if (statisticsMode == n) {
      return "inline-flex p-4 text-blue-600 rounded-t-lg border-b-2 border-blue-600 active dark:text-blue-500 dark:border-blue-500 group";
    } else {
      return "inline-flex p-4 rounded-t-lg border-b-2 border-transparent hover:text-gray-600 hover:border-gray-300 dark:hover:text-gray-300 group";
    }
  }

  function getSvgClass(n) {
    if (statisticsMode == n) {
      return "mr-2 w-5 h-5 text-blue-600 dark:text-blue-500";
    } else {
      return "mr-2 w-5 h-5 text-gray-400 group-hover:text-gray-500 dark:text-gray-500 dark:group-hover:text-gray-300";
    }
  }

  return (
    <>
      <div className="w-full overflow-scroll h-screen pb-40">
        <CustomersHeader />
        <div className="border-b border-gray-200 dark:border-gray-700 ml-12 mt-6 mb-6">
          <ul className="flex flex-wrap -mb-px text-sm font-medium text-center text-gray-500 dark:text-gray-400">
            <li className="mr-2">
              <button
                onClick={() => { setStatisticsMode(0); }}
                className={getButtonClass(0)}
                aria-current="page"
              >
                <svg
                  className={getSvgClass(0)}
                  fill="currentColor"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fillRule="evenodd"
                    d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-6-3a2 2 0 11-4 0 2 2 0 014 0zm-2 4a5 5 0 00-4.546 2.916A5.986 5.986 0 0010 16a5.986 5.986 0 004.546-2.084A5 5 0 0010 11z"
                    clipRule="evenodd"
                  ></path>
                </svg>
                Manage
              </button>
            </li>
            <li className="mr-2">
              <button
                onClick={() => {
                  setStatisticsMode(1);
                }}
                className={getButtonClass(1)}
              >
                <svg
                  className={getSvgClass(1)}
                  fill="currentColor"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path d="M5 3a2 2 0 00-2 2v2a2 2 0 002 2h2a2 2 0 002-2V5a2 2 0 00-2-2H5zM5 11a2 2 0 00-2 2v2a2 2 0 002 2h2a2 2 0 002-2v-2a2 2 0 00-2-2H5zM11 5a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2h-2a2 2 0 01-2-2V5zM11 13a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2h-2a2 2 0 01-2-2v-2z"></path>
                </svg>
                Create Customer
              </button>
            </li>
          </ul>
        </div>

        <div className="ml-8 mr-8">
          {statisticsMode == 0 ? (
            <CustomersTable
              pageNumber={pageNumber}
              setPageNumber={setPageNumber}
            />
          ) : (
            <CreateCustomer goHome={goHome} />
          )}
        </div>


      </div>

    </>
  );
}

export default Customers;
