import { Document, Font, Page, StyleSheet, View } from '@react-pdf/renderer'
import { useEffect, useState } from 'react'

import Amount from './Amount'
import Body from './Body'
import Client from './Client'
import Footer from './Footer'
import Header from './Header'
import ProposalSignature from './ProposalSignature'

const styles = StyleSheet.create({
  body: {
    backgroundColor: '#ffffff',
    fontFamiy: 'Source Sans',
    fontSize: 12,
    // lineHeight: 1.4,
    paddingTop: 32,
    paddingBottom: 16,
    paddingHorizontal: 32,
    height: '100vh',
  },
  top: {
    flex: 1,
  },
  h1: {
    fontSize: 32,
    marginBottom: 16,
  },
})

Font.register({
  family: 'Source Sans',
  src: '/fonts/SourceSansPro-Regular.otf',
})

Font.register({
  src: `/fonts/SourceSansPro-Bold.otf`,
  family: 'Source Sans',
  fontWeight: 'bold',
})

Font.register({
  src: `/fonts/SourceSansPro-It.otf`,
  family: 'Source Sans',
  fontStyle: 'italic',
})





function Invoice(props) {


  // useEffect(() => {
  //   setLoading(true);
  //   // console.log(
  //   //   "thing",
  //   //   `${process.env.REACT_APP_API_ENDPOINT}/billing/get-billables-history/${organizationId}?limit=${LIMIT}&offset=${pageNumber}`
  //   // );
  //   if (organizationId) {
  //     fetch(
  //       `${process.env.REACT_APP_API_ENDPOINT}/billing/get-billables-history/${organizationId}?limit=${LIMIT}&offset=${pageNumber}`
  //     )
  //       .then((response) => response.json())
  //       .then(
  //         (data) => {
  //           console.log("data", data);
  //           setCustomers(data["data"]);
  //           setLoading(false);
  //         }
  //         // }
  //       );
  //   }
  // }, [pageNumber, LIMIT, organizationId]);

  const [invoiceData, setInvoiceData] = useState({})
  const [transaction, setTransaction] = useState({})
  const [customer, setCustomer] = useState({})
  useEffect(() => {
    console.log('transactionId', props.transactionId)
    try {
      fetch(
        `${process.env.REACT_APP_API_ENDPOINT}/invoices/get/${props.transactionId}`
      )
        .then((response) => response.json())
        .then(
          (data) => {
            console.log("data", data);
            setInvoiceData(data["history"]);
            setTransaction(data["transaction"]);
            setCustomer(data["customer"]);
            // setLoading(false);
          }
          // }
        );
    } catch (err) {
      console.error(err);
    }

  }, [props.transactionId])
  // const { body, formType, date, to, address, jobLocation, phone, amount } = data

  // const formTypeName = formType === 'proposal' ? 'Proposal' : 'Invoice'



  let d = {
    companyName: 'Blue Hill Construction',
    address: '1911 N Sayre Ave',
  }

  return (
    <Document>
      <Page style={styles.body} wrap>
        <View style={styles.top}>
          <Header customer={customer} transaction={transaction} formTypeName={"Invoice"} />
          {/* <Client to={to} phone={customer.phoneNumber} address={customer.street} /> */}
          <Body customer={customer} transaction={transaction} invoiceData={invoiceData} />
        </View>
        <View style={styles.bottom}>
          {/* <Amount amount={amount} formType={formType} /> */}
          {/* {formType === 'proposal' && <ProposalSignature />} */}
          <Footer />
        </View>
      </Page>
    </Document>
  )
}

export default Invoice
