import { useEffect, useState, useMemo } from "react";
import { useTable } from "react-table";
import { useOrganizationId } from "../hooks/useOrganizationId";
import Spinner from "../components/Spinner";
import { useAuth0 } from "@auth0/auth0-react";
import InvoiceTemplate from "../invoice/InvoiceTemplate";
import { DownloadIcon } from "@heroicons/react/outline";

function TransactionsTable() {
  const [transactions, setTransactions] = useState([]);
  const [offset, setOffset] = useState(0);
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(true);
  const { user, isAuthenticated, isLoading } = useAuth0();
  const { organizationId } = useOrganizationId();

  const [filter, setFilter] = useState(0);
  const [searchQuery, setSearchQuery] = useState("");
  const LIMIT = 20000;
  useEffect(() => {
    if (organizationId) {
      console.log(
        `${process.env.REACT_APP_API_ENDPOINT}/transactions/v2/get-by-organization/${organizationId}?limit=${LIMIT}&offset=${offset}`
      );
      try {
        fetch(
          `${process.env.REACT_APP_API_ENDPOINT}/transactions/v2/get-by-organization/${organizationId}?limit=${LIMIT}&offset=${offset}`
        )
          .then((response) => response.json())
          .then((res) => {
            setLoading(false);
            setTransactions(res.data);
          });
      } catch (err) {
        console.error(error);
        setError(true);
      }
    }
  }, [organizationId]);

  const columns = useMemo(
    () => [
      {
        Header: "#ID",
        accessor: "id",
        Cell: (props) => props.value.substring(0, 6),
      },
      {
        Header: "Amount",
        accessor: "amount",
        Cell: (props) => formatter.format(props.value),
      },
      {
        Header: "Recurring",
        accessor: "isRecurring",
        Cell: (props) => (props.value ? "Yes" : "No"),
      },
      {
        Header: "Customer ID",
        accessor: "customerId",

        Cell: (props) => (

          <a
            href={`/customers/${props.value}`}
            className=" inline-flex items-center py-1.5 px-4 text-sm font-medium text-gray-900 bg-white rounded-lg border border-gray-200 hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-2 focus:ring-blue-700 focus:text-blue-700 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700">
            <svg className="w-5 h-5 mr-2" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M16 7a4 4 0 11-8 0 4 4 0 018 0zM12 14a7 7 0 00-7 7h14a7 7 0 00-7-7z" /></svg>
            {props.row.original.customerCompany ?
              props.row.original.customerCompany
              : props.row.original.customerName ?
                props.row.original.customerName :
                props.value.substring(0, 6)}
            {console.log('props', props.row.original.customerName)}

          </a>
        ),

      },
      {
        Header: "Fee Amount",
        accessor: "feeAmount",
        Cell: (props) => formatter.format(props.value),
      },
      {
        Header: "Card Type",
        accessor: "cardType",
      },
      {
        Header: "Last 4",
        accessor: "last4",
      },
      {
        Header: "Processed Gateway",
        accessor: "processedGatewayName",
      },
      {
        Header: "Date",
        accessor: "createdAt",
        Cell: (props) => (getStartDate(props.value)),

      },
      {
        Header: "Outcome",
        accessor: "outcome",
        Cell: (props) => {
          console.log(props.value);
          return props.value === "success" ?
            <h1 class="bg-blue-100 text-blue-800 text-xs font-semibold mr-2 px-2.5 py-0.5 rounded dark:bg-blue-200 dark:text-blue-800 w-fit">{props.value.charAt(0).toUpperCase() + props.value.slice(1)}</h1>
            : props.value === "disputed" || props.value === "refunded" ?
              <h1 class="bg-red-100 text-red-800 text-xs font-semibold mr-2 px-2.5 py-0.5 rounded dark:bg-red-200 dark:text-red-900 w-fit">{props.value.charAt(0).toUpperCase() + props.value.slice(1)}</h1>
              : <h1 class="bg-purple-100 text-purple-800 text-xs font-semibold mr-2 px-2.5 py-0.5 rounded dark:bg-purple-200 dark:text-purple-900 w-fit">{props.value.charAt(0).toUpperCase() + props.value.slice(1)}</h1>
        }
      },
      {
        Header: "Details",
        accessor: "id",
        id: "details",
        Cell: (props) => (
          <a
            href={`/transactions/${props.value}`}
            className=" inline-flex items-center py-1.5 px-4 text-sm font-medium text-gray-900 bg-white rounded-lg border border-gray-200 hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-2 focus:ring-blue-700 focus:text-blue-700 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700">
            {/* > */}
            Details
            <svg className="w-4 h-4 ml-2" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 5l7 7-7 7" /></svg>
          </a>
        ),
      },
      {
        Header: "Actions",
        accessor: "id",
        id: "invoice",
        Cell: (props) => (
          // <InvoiceTemplate transactionId={String(props.value)} />
          <></>
        ),
      },
    ],
    [organizationId]
  );

  var formatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",

    // These options are needed to round to whole numbers if that's what you want.
    //minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
    //maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
  });

  const getStartDate = (customer) => {
    const d = new Date(customer);
    const months = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sept",
      "Oct",
      "Nov",
      "Dec",
    ];
    const month = months[d.getMonth()];
    const day = d.getDate();
    const year = d.getFullYear();
    const hour = d.getHours();
    const time = d.get
    const minutes = d.getMinutes();
    if (year == 1969) {
      return '-----'
    }
    return d.toDateString() // `${day} ${month} ${year} ${hour}:${minutes}`;
  };

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable({ columns, data: transactions });

  if (error) return <Spinner table />;
  if (loading || isLoading) return <Spinner table />;


  function exportToCSV(event) {
    var requestOptions = {
      method: 'GET',
      redirect: 'follow'
    };
    var saveAs = require('file-saver');
    fetch(`http://localhost:5000/transactions/v2/get-download/${organizationId}`, requestOptions)
      .then(response => response.blob())
      .then(blob => saveAs(blob, 'test.csv'))
      .catch(error => console.log('error', error));
  }

  // if (transactions.length === 0) return <div>Loading</div>;
  return (
    <div className="flex flex-col">
      <div className="w-full mb-4 mt-4 flex justify-between" >

        <div class="flex w-1/4 ">
          <select
            onChange={(e) => setFilter(e.target.value)}
            id="subscription"
            className="flex-shrink-0 z-10 inline-flex items-center py-2.5  text-sm font-medium text-center text-gray-900 bg-gray-100 border border-gray-300 dark:border-gray-700 dark:text-white rounded-l-lg hover:bg-gray-200 focus:ring-4 focus:outline-none focus:ring-gray-300 dark:bg-gray-600 dark:hover:bg-gray-700 dark:focus:ring-gray-800"
          >
            <option value={0} selected>
              Transaction ID
            </option>
            <option value={3}>
              Customer ID
            </option>
            <option value={5}>Card Type</option>
            <option value={6}>Last 4</option>
            <option value={7}>Gateway</option>
            <option value={9}>Outcome</option>

          </select>
          <div class="relative w-full">
            <input onChange={e => setSearchQuery(e.target.value)} type="search" id="search-dropdown" class="block p-2.5 w-full z-20 text-sm text-gray-900 bg-gray-50 rounded-r-lg border-l-gray-100 border-l-2 border border-gray-300 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:border-blue-500" placeholder="Search" required />
          </div>
        </div>

        <button
          onClick={() => exportToCSV(transactions)}
          // href={`/transactions/${props.value}`}
          className="inline-flex items-center py-1.5 px-4 text-sm font-medium text-gray-900 bg-white rounded-lg border border-gray-200 hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-2 focus:ring-blue-700 focus:text-blue-700 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700">
          {/* > */}
          Export CSV
          <DownloadIcon className="w-4 h-4 ml-2" />
          {/* <svg className="w-4 h-4 ml-2" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 5l7 7-7 7" /></svg> */}
        </button>
      </div>
      <div className=" sm:-mx-6 lg:-mx-8">
        <div className="inline-block py-2 min-w-full sm:px-6 lg:px-8">
          <div className="overflow-hidden shadow-md sm:rounded-lg">
            <table {...getTableProps()} className="w-full">
              <thead className="bg-gray-50 dark:bg-gray-700">
                {headerGroups.map((headerGroup) => (
                  <tr {...headerGroup.getHeaderGroupProps()}>
                    {headerGroup.headers.map((column) => (
                      <th
                        {...column.getHeaderProps()}
                        className="py-3 px-6 text-xs font-medium tracking-wider text-left text-gray-700 uppercase dark:text-gray-400"
                      >
                        {column.render("Header")}
                      </th>
                    ))}
                  </tr>
                ))}
              </thead>
              <tbody className="max-w-full divide-y" {...getTableBodyProps()}>
                {rows
                  .filter(p => searchQuery != "" ?
                    p.cells[filter].value.toLowerCase().includes(searchQuery.toLowerCase()) : p)

                  .map((row, index) => {
                    prepareRow(row);
                    console.log("row", row);
                    return (
                      <tr {...row.getRowProps()} className={index % 2 == 0 ? "bg-white border-b dark:bg-gray-800 dark:border-gray-700 h-10" : "bg-gray-100 border-b dark:bg-gray-800 dark:border-gray-700 h-10"}>
                        {row.cells.map((cell) => {
                          return (
                            <td
                              {...cell.getCellProps()}
                              className="py-2 px-6 text-sm text-gray-500 whitespace-nowrap dark:text-gray-400"
                            >
                              {cell.render("Cell")}
                            </td>
                          );
                        })}
                      </tr>
                    );
                  })}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
}

export default TransactionsTable;
