import { useEffect, useState } from "react";
// import { useOrganizationId } from "../hooks/useOrganizationId";

import { useDashboardData } from "../hooks/useDashboardData";
import LoadingPage from "../components/LoadingPage";

import { ArrowDownIcon, ArrowUpIcon } from "@heroicons/react/solid";
var formatter = new Intl.NumberFormat("en-US", {
  style: "currency",
  currency: "USD",

  // These options are needed to round to whole numbers if that's what you want.
  //minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
  //maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
});

function DashboardStatCard({ color = "bg-blue-100", ...props }) {

  const { totalFee, amountProcessed, averageTransaction, customersAdded } =
    useDashboardData();
  console.log({
    totalFee,
    amountProcessed,
    averageTransaction,
    customersAdded,
  });

  const dataEnum = {
    "Fee Paid": formatter.format(totalFee),
    "Avg. Transaction": formatter.format(averageTransaction),
    "Amount Processed": formatter.format(amountProcessed),
    "Customers Added": customersAdded,
  };

  function classNames(...classes) {
    return classes.filter(Boolean).join(" ");
  }

  if (totalFee && amountProcessed && averageTransaction) {
    return (
      <div className="bg-white border-gray-200 px-2 sm:px-4 py-3 dark:bg-gray-800 drop-shadow-xl w-1/6 h-20 rounded-lg">
        <div className="flex flex-row justify-between h-full" align-middle>
          <div
            className={
              `flex justify-center items-center mr-4 rounded-md p-3 ${color}`
            }
          >
            {props.img}
          </div>

          <div className="flex flex-col justify-between w-3/4 h-full">
            <div className="flex flex-col ">
              <h1 className="text-2xl font-medium dark:text-white flex items-center flex-row">
                {dataEnum[props.description]}
              </h1>

              <h1 className="text-md dark:text-white">{props.description}</h1>
            </div>
          </div>
        </div>
      </div>
    );
  } else {
    return <LoadingPage />;
  }
}

export default DashboardStatCard;
