import { useEffect, useState } from "react";
import { useOrganizationId } from "../hooks/useOrganizationId";
import Spinner from "../components/Spinner";
import { MailIcon, SearchIcon } from "@heroicons/react/outline";
function CustomersTable({ pageNumber, setPageNumber }) {
  const [customers, setCustomers] = useState([]);
  const { organizationId } = useOrganizationId();
  const [offset, setOffset] = useState(0);
  const LIMIT = 15;
  const [loading, setLoading] = useState(true);
  const [searchQuery, setSearchQuery] = useState("");
  const [filter, setFilter] = useState("firstName");

  function searchChange(e) {
    console.log(e)
    setFilter(e);
    // setCustomers()


  }
  var formatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",

    // These options are needed to round to whole numbers if that's what you want.
    //minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
    //maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
  });
  useEffect(() => {
    setLoading(true);
    if (organizationId) {
      fetch(
        `${process.env.REACT_APP_API_ENDPOINT}/customers/v2/get-by-organization/${organizationId}?limit=${LIMIT}&offset=${pageNumber}&ltv=true`
      )
        .then((response) => response.json())
        .then(
          (data) => {
            setCustomers(data["data"]);
            setLoading(false);
          }
          // }
        );
    }
  }, [pageNumber, LIMIT, organizationId]);

  if (loading) {
    return <Spinner table />;
  }

  function truncate(str, n) {
    return (str.length > n) ? str.slice(0, n - 1) + '...' : str;
  };


  return (
    <div className="flex flex-col">

      <div className="w-full mb-4 mt-4 flex justify-end overflow-x-scroll" >
        <div class="relative mb-4 mt-4 mr-12 w-1/5">
          <div class="flex absolute inset-y-0 left-0 items-center pl-3 pointer-events-none">
            <SearchIcon className="h-5 w-5" aria-hidden="true" />
          </div>

          <input
            name=""
            placeholder="Search"
            autoComplete="off"
            onChange={(e) => setSearchQuery(e.target.value)}
            class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full pl-10 p-2.5  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
          />
        </div>
      </div>



      <div className="overflow-x-scroll sm:-mx-6 lg:-mx-8">
        <div className="inline-block py-2 min-w-full sm:px-6 lg:px-8">
          <div className="overflow-hidden shadow-md sm:rounded-lg">
            <table className="min-w-full">
              <thead className="bg-gray-50 dark:bg-gray-700">
                <tr>
                  <th scope="col" className="py-3 px-6 text-xs font-medium tracking-wider text-left text-gray-700 uppercase dark:text-gray-400 w1">
                    Organization
                  </th>
                  <th scope="col" className="py-3 px-6 text-xs font-medium tracking-wider text-left text-gray-700 uppercase dark:text-gray-400">
                    Name
                  </th>
                  <th
                    scope="col"
                    className="py-1 px-6 text-xs font-medium tracking-wider text-left text-gray-700 uppercase dark:text-gray-400"
                  >
                    Email
                  </th>
                  <th scope="col" className="py-3 px-6 text-xs font-medium tracking-wider text-left text-gray-700 uppercase dark:text-gray-400">
                    Phone #
                  </th>
                  <th scope="col" className="py-3 px-6 text-xs font-medium tracking-wider text-left text-gray-700 uppercase dark:text-gray-400">
                    Address
                  </th>
                  {/* <th scope="col" className="py-3 px-6 text-xs font-medium tracking-wider text-left text-gray-700 uppercase dark:text-gray-400">
                    Town
                  </th>
                  <th scope="col" className="py-3 px-6 text-xs font-medium tracking-wider text-left text-gray-700 uppercase dark:text-gray-400">
                    State
                  </th> */}
                  <th scope="col" className="py-3 px-6 text-xs font-medium tracking-wider text-left text-gray-700 uppercase dark:text-gray-400">
                    Zip
                  </th>
                  <th scope="col" className="py-3 px-6 text-xs font-medium tracking-wider text-left text-gray-700 uppercase dark:text-gray-400">
                    LTV
                  </th>

                  <th scope="col" className="relative py-3 px-6">
                    <span className="sr-only">Details</span>
                  </th>
                </tr>
              </thead>
              <tbody>
                {customers
                  .filter(p => searchQuery != "" ?
                    p.company?.toLowerCase().includes(searchQuery.toLocaleLowerCase()) ||
                    p.firstName?.toLowerCase().includes(searchQuery.toLocaleLowerCase()) ||
                    p.lastName?.toLowerCase().includes(searchQuery.toLocaleLowerCase()) ||
                    p.email?.toLowerCase().includes(searchQuery.toLocaleLowerCase()) ||
                    p.phoneNumber?.toLowerCase().includes(searchQuery.toLocaleLowerCase()) || p.id?.toLocaleLowerCase().includes(searchQuery.toLocaleLowerCase()) : p)
                  .map((customer, index) => (
                    <tr className={index % 2 == 0 ? "bg-white border-b dark:bg-gray-800 dark:border-gray-700 h-10" : "bg-gray-100 border-b dark:bg-gray-800 dark:border-gray-700 h-10"}>
                      <td className="py-2 px-6 text-sm font-medium text-gray-900 whitespace-nowrap dark:text-white truncate">
                        <p className="w-1/8 truncate">{truncate(customer.company, 26)}</p>
                      </td>
                      <td className="py-2 px-6 text-sm text-gray-500 whitespace-nowrap dark:text-gray-400">
                        {customer.firstName} {customer.lastName}
                      </td>

                      <td className="py-2 px-6 text-sm text-gray-500 whitespace-nowrap dark:text-gray-400">
                        <a
                          href={"mailto:" + customer.email}
                          className=" inline-flex items-center py-1.5 px-4 text-sm font-medium text-gray-900 bg-white rounded-lg border border-gray-200 hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-2 focus:ring-blue-700 focus:text-blue-700 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700">
                          <MailIcon className="w-4 h-4 mr-2" aria-hidden="true" />
                          Email
                          {/* <svg className="w-4 h-4 ml-2" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 5l7 7-7 7" /></svg> */}

                        </a>
                        {/* {customer.email} */}
                      </td>
                      <td className="py-2 px-6 text-sm text-gray-500 whitespace-nowrap dark:text-gray-400">
                        {customer.phoneNumber}
                      </td>
                      <td className="py-2 px-6 text-sm text-gray-500 whitespace-nowrap dark:text-gray-400">
                        {/* <p className="w-1/8 truncate">{truncate(customer.company, 26)}</p> */}

                        {customer.town}, {customer.state}
                      </td>
                      {/* <td className="py-2 px-6 text-sm text-gray-500 whitespace-nowrap dark:text-gray-400">

                      </td>
                      <td className="py-2 px-6 text-sm text-gray-500 whitespace-nowrap dark:text-gray-400">

                      </td> */}
                      <td className="py-2 px-6 text-sm text-gray-500 whitespace-nowrap dark:text-gray-400">
                        {customer.zipCode}
                      </td>
                      <td className="py-2 px-6 text-sm text-gray-500 whitespace-nowrap dark:text-gray-400 ">

                        <div class="px-2 py-1.5 text-xs font-semibold text-gray-800 bg-gray-100 border border-gray-200 rounded-lg dark:bg-gray-600 dark:text-gray-100 dark:border-gray-500 items-center flex justify-center ">{formatter.format(customer.ltv)}</div>

                      </td>
                      <td className="py-2 px-6 text-sm font-medium text-right whitespace-nowrap">
                        <a
                          href={"/customers/" + customer.id}
                          className=" inline-flex items-center py-1.5 px-4 text-sm font-medium text-gray-900 bg-white rounded-lg border border-gray-200 hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-2 focus:ring-blue-700 focus:text-blue-700 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700">

                          Details
                          <svg className="w-4 h-4 ml-2" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 5l7 7-7 7" /></svg>

                        </a>
                      </td>
                    </tr>
                  ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
}

export default CustomersTable;
