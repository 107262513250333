/* This example requires Tailwind CSS v2.0+ */
import { Fragment, useState } from "react";
import { Listbox, Transition } from "@headlessui/react";
import { CheckIcon, SelectorIcon } from "@heroicons/react/solid";
import { useEffect } from "react";

const people = [{ id: 0, name: "Alabama" }, { id: 1, name: "Alaska" }, { id: 2, name: "American Samoa" }, { id: 3, name: "Arizona" }, { id: 4, name: "Arkansas" }, { id: 5, name: "California" }, { id: 6, name: "Colorado" }, { id: 7, name: "Connecticut" }, { id: 8, name: "Delaware" }, { id: 9, name: "District of Columbia" }, { id: 10, name: "Federated States of Micronesia" }, { id: 11, name: "Florida" }, { id: 12, name: "Georgia" }, { id: 13, name: "Guam" }, { id: 14, name: "Hawaii" }, { id: 15, name: "Idaho" }, { id: 16, name: "Illinois" }, { id: 17, name: "Indiana" }, { id: 18, name: "Iowa" }, { id: 19, name: "Kansas" }, { id: 20, name: "Kentucky" }, { id: 21, name: "Louisiana" }, { id: 22, name: "Maine" }, { id: 23, name: "Marshall Islands" }, { id: 24, name: "Maryland" }, { id: 25, name: "Massachusetts" }, { id: 26, name: "Michigan" }, { id: 27, name: "Minnesota" }, { id: 28, name: "Mississippi" }, { id: 29, name: "Missouri" }, { id: 30, name: "Montana" }, { id: 31, name: "Nebraska" }, { id: 32, name: "Nevada" }, { id: 33, name: "New Hampshire" }, { id: 34, name: "New Jersey" }, { id: 35, name: "New Mexico" }, { id: 36, name: "New York" }, { id: 37, name: "North Carolina" }, { id: 38, name: "North Dakota" }, { id: 39, name: "Northern Mariana Islands" }, { id: 40, name: "Ohio" }, { id: 41, name: "Oklahoma" }, { id: 42, name: "Oregon" }, { id: 43, name: "Palau" }, { id: 44, name: "Pennsylvania" }, { id: 45, name: "Puerto Rico" }, { id: 46, name: "Rhode Island" }, { id: 47, name: "South Carolina" }, { id: 48, name: "South Dakota" }, { id: 49, name: "Tennessee" }, { id: 50, name: "Texas" }, { id: 51, name: "Utah" }, { id: 52, name: "Vermont" }, { id: 53, name: "Virgin Island" }, { id: 54, name: "Virginia" }, { id: 55, name: "Washington" }, { id: 56, name: "West Virginia" }, { id: 57, name: "Wisconsin" }, { id: 58, name: "Wyoming" }];
function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function StateSelect(props) {
  const [selected, setSelected] = useState(props.defaultState);

  useEffect(() => {
    console.log(props.defaultState)
    console.log("selected", selected);



    props.valueFunc(selected == null ? "none" : selected.name);

  }, [selected]);


  useEffect(() => {
    setSelected(props.defaultState);
    console.log("selected", selected);
  }, [props.defaultState]);

  return (
    <Listbox value={selected} onChange={setSelected}>
      {({ open }) => (
        <>
          <Listbox.Label className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300">State</Listbox.Label>
          <div className="mt-2 relative mb-6">
            <Listbox.Button className="relative w-full bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500">
              <span className="flex items-center">
                {
                  selected != null
                    ? (
                      <>
                        {/* <img src={selected.avatar} alt="" className="flex-shrink-0 h-6 w-6 rounded-full" /> */}
                        <span className="ml-3 block truncate">{selected != null ? selected.name : "State"}</span>
                      </>
                    )
                    : <span className="ml-3 block truncate">{selected != null ? selected.name : "State"}</span>
                }

              </span>
              <span className="ml-3 absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
                <SelectorIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
              </span>
            </Listbox.Button>

            <Transition
              show={open}
              as={Fragment}
              leave="transition ease-in duration-100"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Listbox.Options className="absolute z-10 mt-1 w-full bg-white shadow-lg max-h-56 rounded-md py-1 text-base ring-1 ring-black ring-opacity-5 overflow-auto focus:outline-none sm:text-sm">
                {people.map((person) => (
                  <Listbox.Option
                    key={person.id}
                    className={({ active }) =>
                      classNames(
                        active ? "text-white bg-indigo-600" : "text-gray-900",
                        "cursor-default select-none relative py-2 pl-3 pr-9",
                      )}
                    value={person}
                  >
                    {({ selected, active }) => (
                      <>
                        <div className="flex items-center">
                          {/* <img src={person.avatar} alt="" className="flex-shrink-0 h-6 w-6 rounded-full" /> */}
                          <span
                            className={classNames(selected ? "font-semibold" : "font-normal", "ml-3 block truncate   flex items-center pr-4 h-6 py-2s")}
                          >
                            {person.name}
                          </span>
                        </div>

                        {selected ? (
                          <span
                            className={classNames(
                              active ? "text-white" : "text-indigo-600",
                              "absolute inset-y-0 right-0 flex items-center pr-4 h-6 py-2s",
                            )}
                          >
                            <CheckIcon className="h-6 w-5" aria-hidden="true" />
                          </span>
                        ) : null}
                      </>
                    )}
                  </Listbox.Option>
                ))}
              </Listbox.Options>
            </Transition>
          </div>
        </>
      )}
    </Listbox>
  );
}
