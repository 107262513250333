import React from 'react';
import { withAuthenticationRequired } from '@auth0/auth0-react';
import LoadingPage from './LoadingPage';

export const PrivateRoute = ({
  component,
  ...args
}) => {
  const Component = withAuthenticationRequired(component, {
    onRedirecting: () => (
      <LoadingPage />
    ),
  });
  return <Component />;
};