import { ExclamationCircleIcon } from '@heroicons/react/solid'

export default function Alert(props) {

  const handleNo = () => {
    try {
      fetch(`${process.env.REACT_APP_API_ENDPOINT}/onboarding/getAccountId/${props.organizationId}`)
        .then((res) => res.json())
        .then((data) => {
          const accountId = data.accountId;
          alert(accountId);
          // return;
          fetch(`${process.env.REACT_APP_API_ENDPOINT}/onboarding/connect/createId`)
          .then((res) => res.json())
          .then((data) => {
            alert(JSON.stringify({ connectId: data.id, accountId: accountId }));
            const requestOptions = {
              method: "POST",
              // headers: { "Content-Type": "application/json" },
              body: JSON.stringify({ connectId: data.id, accountId: accountId }),
            };
            // console.log("data", data);
            // setConnectId(data.id);
            fetch(
              `${process.env.REACT_APP_API_ENDPOINT}/onboarding/connect/link`,
              requestOptions
            )
              .then((res) => res.json())
              .then((data) => {
                console.log("data", data);
                // console.log
                // seturl(data.url);
                window.location.href = data.url;
                return false;
              });
          });
        });

    } catch (err) {
      console.error(err);
    }
  };
  return (
    <div className="border-l-4 border-blue-400 bg-blue-50 p-4">
      <div className="flex">
        <div className="flex-shrink-0">
          <ExclamationCircleIcon className="h-5 w-5 text-blue-400" aria-hidden="true" />
        </div>
        <div className="ml-3">
          <p className="text-sm text-blue-700">
            Your account is in sandbox mode until you add gateway credentials. {" "} Visit{" "}
            <a href="/integrations" className="font-medium text-blue-700 underline hover:text-blue-600">
              Integrations
            </a>
            {" "}or{" "}
            <button onClick={handleNo} className="font-medium text-blue-700 underline hover:text-blue-600">
              create a new gateway account
            </button>
          </p>
        </div>
      </div>
    </div>
  )
}