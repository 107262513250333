import { StyleSheet, Text, View } from '@react-pdf/renderer'
// import { Table, TableHeader, TableCell, TableBody, DataTableCell } from "@david`.kucsai/react-pdf-table"
const styles = StyleSheet.create({
  container: {
    borderTop: '1px double #666',
    marginTop: 24,
    paddingTop: 24,
  },
  bold: {
    fontSize: 11,
  },
  body: {
    // borderLeft: '1px solid #aaa',
    lineHeight: 1.8,
    marginLeft: 4,
    marginTop: 8,
    paddingLeft: 8,
  },

  table: {
    display: "table",
    // width: "auto",
    borderStyle: "solid",
    borderWidth: 1,
    borderColor: "#bfbfbf",
    borderRightWidth: 0,
    borderBottomWidth: 0,
  },
  tableRow: {
    // margin: "auto",
    flexDirection: "row"
  },
  tableCol: {
    width: "25%",
    borderStyle: "solid",
    borderWidth: 1,
    borderColor: "#bfbfbf",
    borderLeftWidth: 0,
    borderTopWidth: 0
  },

  tableColLarge: {
    width: "75%",
    borderStyle: "solid",
    borderWidth: 1,
    borderColor: "#bfbfbf",
    borderLeftWidth: 0,
    borderTopWidth: 0
  },
  tableCell: {
    margin: "auto",
    marginTop: 5,
    fontSize: 10
  }

})

function Body({ invoiceData, customer, transaction, formType, body, jobLocation }) {
  // const text =
  //   formType === 'proposal'
  //     ? 'We propose hereby to furnish labor, material, equipment, and insuranace necessary for the completion of:'
  //     : 'Specifications for Work Completed'
  console.log('iv', Object.values(invoiceData))
  let d = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12]
  const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',

    // These options are needed to round to whole numbers if that's what you want.
    //minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
    //maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
  });



  return (
    <View style={styles.container}>


      <Text style={{ marginBottom: 12, marginTop: 12 }}>Summary: {jobLocation}</Text>

      <View style={{ ...styles.table, width: "50%", marginTop: 10 }}>
        <View style={{ ...styles.tableRow, }}>
          <View style={styles.tableColLarge}>
            <Text style={styles.tableCell}>Subtotal</Text>
          </View>
          <View style={styles.tableColLarge}>
            <Text style={styles.tableCell}>Fees</Text>
          </View>
          <View style={styles.tableColLarge}>
            <Text style={styles.tableCell}>Total</Text>
          </View>
          {/* <View style={styles.tableCol}>
            <Text style={styles.tableCell}>Period</Text>
          </View>
          <View style={styles.tableCol}>
            <Text style={styles.tableCell}>Price</Text>
          </View> */}
        </View>

        <View style={styles.tableRow}>
          <View style={styles.tableColLarge}>
            <Text style={styles.tableCell}>{formatter.format(transaction.amount - transaction.feeAmount)}</Text>
          </View>
          <View style={styles.tableColLarge}>
            <Text style={styles.tableCell}>{formatter.format(transaction.feeAmount)} </Text>
          </View>
          <View style={styles.tableColLarge}>
            <Text style={styles.tableCell}>{formatter.format(transaction.amount)} </Text>
          </View>
          {/* <View style={styles.tableCol}>
                <Text style={styles.tableCell}>2019-02-20 - 2020-02-19</Text>
              </View>
              <View style={styles.tableCol}>
                <Text style={styles.tableCell}>5€</Text>
              </View> */}
        </View>


      </View>




      <Text style={{ marginBottom: 12, marginTop: 12 }}>Payment Method: {jobLocation}</Text>
      <View style={{ ...styles.table, width: "50%" }}>
        <View style={{ ...styles.tableRow, }}>
          <View style={styles.tableColLarge}>
            <Text style={styles.tableCell}>Payment Method</Text>
          </View>
          <View style={styles.tableColLarge}>
            <Text style={styles.tableCell}>Last 4</Text>
          </View>
          {/* <View style={styles.tableCol}>
            <Text style={styles.tableCell}>Period</Text>
          </View>
          <View style={styles.tableCol}>
            <Text style={styles.tableCell}>Price</Text>
          </View> */}
        </View>

        <View style={styles.tableRow}>
          <View style={styles.tableColLarge}>
            <Text style={styles.tableCell}>{transaction.cardType}</Text>
          </View>
          <View style={styles.tableColLarge}>
            <Text style={styles.tableCell}>{transaction.last4} </Text>
          </View>
          {/* <View style={styles.tableCol}>
                <Text style={styles.tableCell}>2019-02-20 - 2020-02-19</Text>
              </View>
              <View style={styles.tableCol}>
                <Text style={styles.tableCell}>5€</Text>
              </View> */}
        </View>


      </View>



      <Text style={{ marginBottom: 12, marginTop: 12 }}>Items: {jobLocation}</Text>
      <View style={styles.table}>
        <View style={styles.tableRow}>
          <View style={styles.tableColLarge}>
            <Text style={styles.tableCell}>Product</Text>
          </View>
          <View style={styles.tableCol}>
            <Text style={styles.tableCell}>Amount</Text>
          </View>
          {/* <View style={styles.tableCol}>
            <Text style={styles.tableCell}>Fee</Text>
          </View> */}
          {/* <View style={styles.tableCol}>
            <Text style={styles.tableCell}>Period</Text>
          </View>
          <View style={styles.tableCol}>
            <Text style={styles.tableCell}>Price</Text>
          </View> */}
        </View>

        {
          Object.values(invoiceData).length > 0 ?
            Object.values(invoiceData).map(item => {
              return <View style={styles.tableRow}>
                <View style={styles.tableColLarge}>
                  <Text style={{ ...styles.tableCell, flex: 1 }}>{item.productName}</Text>
                </View>
                <View style={styles.tableCol}>
                  <Text style={styles.tableCell}>{item.fee ? formatter.format(item.amount * (1 + item.fee / 100)) : formatter.format(item.amount)} </Text>
                </View>
                {/* <View style={styles.tableCol}>
                <Text style={styles.tableCell}>{item.fee}% </Text>
              </View> */}
                {/* <View style={styles.tableCol}>
                <Text style={styles.tableCell}>2019-02-20 - 2020-02-19</Text>
              </View>
              <View style={styles.tableCol}>
                <Text style={styles.tableCell}>5€</Text>
              </View> */}
              </View>

            })
            :

            <View style={styles.tableRow}>
              <View style={styles.tableColLarge}>
                <Text style={{ ...styles.tableCell, flex: 1 }}>{transaction.planName}</Text>
              </View>
              <View style={styles.tableCol}>
                {/* <Text style={styles.tableCell}>{item.fee ? formatter.format(item.amount * (1 + item.fee / 100)) : formatter.format(item.amount)} </Text> */}
              </View>
            </View>
        }

      </View>



      <Text style={{ marginBottom: 8, marginTop: 20 }}>Total: {jobLocation}</Text>




      <Text style={{ marginBottom: 8, fontSize: 14 }}>{formatter.format(transaction.amount)}</Text>



      {/* <Text style={styles.bold}>{text}</Text> */}
      {/* <Text style={styles.body}>HEllo</Text> */}
    </View>
  )
}

export default Body
