import Spinner from "./components/LoadingPage";
import Loadable from "react-loadable";
// import BillingHistoryDashboard from "./BillingHistoryDashboard";

export const Dashboard = Loadable({
  loader: () => import("./Dashboard/Dashboard"),
  loading: Spinner,
});


export const SellerProfile = Loadable({
  loader: () => import("./Sellers/SellerProfile"),
  loading: Spinner,
});

export const Sellers = Loadable({
  loader: () => import("./Sellers/Sellers"),
  loading: Spinner,
});



export const BillingDashboard = Loadable({
  loader: () => import("./Billables/BillingDashboard"),
  loading: Spinner,
});

export const Checkout = Loadable({
  loader: () => import("./Checkout/Checkout"),
  loading: Spinner,
});

export const ProductsView = Loadable({
  loader: () => import("./Products/ProductsView"),
  loading: Spinner,
});


export const Workflow = Loadable({
  loader: () => import("./Workflow/Workflow"),
  loading: Spinner,
});

export const WorkflowHistory = Loadable({
  loader: () => import("./WorkflowHistory/WorkflowHistory"),
  loading: Spinner,
});

// export const AuthAnalytics = Loadable({
//   loader: () => import("./AuthAnalytics"),
//   loading: Spinner,
// });

export const BillingHistoryDashboard = Loadable({
  loader: () => import("./Billables/BillingHistoryDashboard"),
  loading: Spinner,
});
// import Flow from "./Flow";

export const Customers = Loadable({
  loader: () => import("./Customers/Customers"),
  loading: Spinner,
});

export const Transactions = Loadable({
  loader: () => import("./Transactions/Transactions"),
  loading: Spinner,
});

export const TransactionDetail = Loadable({
  loader: () => import("./Transactions/TransactionDetail"),
  loading: Spinner,
});

export const CreateTransaction = Loadable({
  loader: () => import("./CustomerDetails/CreateTransaction"),
  loading: Spinner,
});

export const CreateCustomer = Loadable({
  loader: () => import("./Customers/CreateCustomer"),
  loading: Spinner,
});

// export const CreateProductPage = Loadable({
//   loader: () => import("./CreateProductPage"),
//   loading: Spinner,
// });

export const CustomerDetail = Loadable({
  loader: () => import("./CustomerDetails/CustomerDetail"),
  loading: Spinner,
});

export const Products = Loadable({
  loader: () => import("./Products/Products"),
  loading: Spinner,
});

export const Integrations = Loadable({
  loader: () => import("./Integrations/Integrations"),
  loading: Spinner,
});

export const Settings = Loadable({
  loader: () => import("./Settings/Settings"),
  loading: Spinner,
});

// export const Profile = Loadable({
//   loader: () => import("./Profile"),
//   loading: Spinner,
// });

export const Onboarding = Loadable({
  loader: () => import("./Onboarding/Onboarding"),
  loading: Spinner,
});

export const Disputes = Loadable({
  loader: () => import("./views/Disputes/Disputes"),
  loading: Spinner,
});

export const OnboardingIntegrations = Loadable({
  loader: () => import("./Integrations/IntegrationsPage"),
  loading: Spinner,
});

export const Invoices = Loadable({
  loader: () => import("./views/Invoices/index"),
  loading: Spinner,
});

