import { ArrowDownIcon, ArrowUpIcon } from '@heroicons/react/solid'
import { UsersIcon, CreditCardIcon, BadgeCheckIcon, CashIcon, CalculatorIcon } from '@heroicons/react/outline'
import { useDashboardData } from '../hooks/useDashboardData'








function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

export default function StatsCards() {
  var formatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",

    // These options are needed to round to whole numbers if that's what you want.
    //minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
    //maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
  });
  const { totalFee, amountProcessed, averageTransaction, customersAdded } = useDashboardData();
  const stats = [
    { id: 1, name: 'Amount Processed', stat: formatter.format(amountProcessed), icon: CreditCardIcon, change: '122', changeType: 'increase' },
    { id: 2, name: 'Auth Success Rate', stat: '71.42%', icon: BadgeCheckIcon, change: '5.4%', changeType: 'increase' },
    { id: 3, name: 'Avg. Transaction', stat: formatter.format(averageTransaction), icon: CashIcon, change: '3.2%', changeType: 'decrease' },
    { id: 4, name: 'Fees Paid', stat: formatter.format(totalFee), icon: CalculatorIcon, change: '3.2%', changeType: 'decrease' },
    // { id: 5, name: 'Transactions', stat: '24.57%', icon: UsersIcon, change: '3.2%', changeType: 'decrease' },
  ]

  return (
    <div className='mr-12 ml-12'>
      <h3 className="text-lg font-medium leading-6 text-gray-900 dark:text-white">Last 30 days</h3>

      <dl className="mt-5 grid grid-cols-1 gap-5 sm:grid-cols-2 lg:grid-cols-4">
        {stats.map((item) => (
          <div
            key={item.id}
            className="relative overflow-hidden rounded-lg bg-white dark:bg-gray-700 px-4 pt-5 pb-12 shadow sm:px-6 sm:pt-6"
          >
            <dt>
              <div className="absolute rounded-md bg-blue-500 p-3">
                <item.icon className="h-6 w-6 text-white" aria-hidden="true" />
              </div>
              <p className="ml-16 truncate text-sm font-medium text-gray-500 dark:text-gray-200">{item.name}</p>
            </dt>
            <dd className="ml-16 flex items-baseline pb-6 sm:pb-7">
              <p className="text-2xl font-semibold text-gray-900 dark:text-white">{item.stat}</p>
              <p
                className={classNames(
                  item.changeType === 'increase' ? 'text-green-600' : 'text-red-600',
                  'ml-2 flex items-baseline text-sm font-semibold'
                )}
              >
                {item.changeType === 'increase' ? (
                  <ArrowUpIcon className="h-5 w-5 flex-shrink-0 self-center text-green-500" aria-hidden="true" />
                ) : (
                  <ArrowDownIcon className="h-5 w-5 flex-shrink-0 self-center text-red-500" aria-hidden="true" />
                )}

                <span className="sr-only"> {item.changeType === 'increase' ? 'Increased' : 'Decreased'} by </span>
                {item.change}
              </p>
              <div className="absolute inset-x-0 bottom-0 bg-gray-50 dark:bg-gray-800 px-4 py-4 sm:px-6">
                <div className="text-sm">
                  <a href="#" className="font-medium text-blue-600 hover:text-blue-500">
                    {' '}
                    View all<span className="sr-only"> {item.name} stats</span>
                  </a>
                </div>
              </div>
            </dd>
          </div>
        ))}
      </dl>
    </div>
  )
}
