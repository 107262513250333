// import "./styles.css";
import { LineChart, AreaChart, PieChart } from "react-chartkick";
import "chart.js";
import { useEffect, useState } from "react";
import { fetcher } from "../utils/fetcher";
import useSWR from "swr";
import { useOrganizationId } from "../hooks/useOrganizationId";
import Spinner from "../components/Spinner";
// import Graph from './Graph';

export default function AuthGraph(props) {
  const [transactions, setTransactions] = useState([]);
  const [offset, setOffset] = useState(0);
  const [limit, setLimit] = useState(6);
  const { organizationId } = useOrganizationId();
  const { data, error } = useSWR(
    organizationId
      ? `${process.env.REACT_APP_API_ENDPOINT}/dashboard/v2/get-auth/${organizationId}`
      : null,
    fetcher
  );

  if (error) return <div>{error}</div>;
  if (!data) return <Spinner table />;
  return (
    <div className="relative overflow-hidden rounded-lg bg-white dark:bg-gray-700 shadow  mt-12 pb-12 ">

      <div className="inset-x-0 top-0 bg-gray-50 dark:bg-gray-800 px-4 py-4 sm:px-6 flex flex-row">
        <div className="text-lg dark:text-white w-full">


          <p className="text-2xl font-semibold text-gray-900 dark:text-white">Authorizations </p>
          <p className="truncate text-sm font-medium text-gray-500 dark:text-gray-200 w-full">See the breakdown of authorized transactions</p>

        </div>

      </div>


      {/* <h1 className="text-2xl font-medium dark:text-gray-200 mt-4">Network Breakdown</h1> */}
      < div className="w-full flex flex-col justify-center mt-12" >

        <div className="w-full">
          <PieChart data={data.data} colors={["#1e3a8acc", "#1e40afcc", "#1d4ed8cc", "#2563ebcc", "#3b82f6cc", "#60a5facc", "#93c5fdcc", "#bfdbfecc"]} />
        </div>

        {
          props.showDescription ?
            <div class="overflow-x-auto relative sm:rounded-lg mt-12 px-12 mb-12 rounded overflow-hidden">
              <table class="w-full text-sm text-left text-gray-500 dark:text-gray-400 rounded">
                <thead class="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                  <tr>
                    <th scope="col" class="py-3 px-6">
                      Payment Method
                    </th>
                    <th scope="col" class="py-3 px-6">
                      # Transactions
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {console.log(data.data)}
                  {
                    Object.keys(data.data).map((k, v) => {
                      // data.data.map((item, index) => {
                      return <tr class="bg-white border-b dark:bg-gray-900 dark:border-gray-700">
                        <th scope="row" class="py-4 px-6 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                          {k}
                        </th>
                        <td class="py-4 px-6">
                          {data.data[k]}
                        </td>

                      </tr>
                    })
                  }

                </tbody>
              </table>
            </div>



            : null
        }
      </div >
    </div >
  );
}
