import "./styles.css";

// import NavBar from "./NavBar";
import DashboardStatCard from "./DashboardStatCard";
import Graph from "../Graphs/Graph";
import CardNetworkGraph from "../Graphs/CardNetworkGraph";
import ProcessorGraph from "../Graphs/ProcessorGraph";
import Timeline from "../Timeline";
// import DisputesTable from "./DisputesTable";
import { useAuth0 } from "@auth0/auth0-react";
import { useEffect, ReactElement, useState } from "react";
import { useSWRConfig } from 'swr'
import { useOrganizationId } from "../hooks/useOrganizationId";
import { CopyBlock, monoBlue } from "react-code-blocks";
import OnboardingView from "./OnboardingView";
import PopularProductsGraph from "../Graphs/PopularProductsGraph";
import StatsCards from "./StatsCard";
import TransactionsTable from "../Transactions/TransactionsTable";
import AuthGraph from "../Graphs/AuthGraph";
import LoadingPage from "../components/LoadingPage";
import ConnectModal from "../Onboarding/ConnectModal";
import { useAtom } from "jotai";
import { showOnboardingModalAtom } from "../store/store";
import Alert from "./Alert";
const Dashboard = () => {
  const { user, isAuthenticated, getAccessTokenSilently } = useAuth0();
  const { cache, mutate, ...extraConfig } = useSWRConfig()
  const [accessToken, setAccessToken] = useState(null);
  console.log(cache)
  const [showModal, setShowModal] = useState(false)
  // console.log(isAuthenticated);
  console.log(showModal)
  const { organizationId, hasOnboarded, isLoading } = useOrganizationId()

  useEffect(() => {
    console.log(`${process.env.REACT_APP_API_ENDPOINT}/onboarding/check-visit/${user.email}`)
    fetch(`${process.env.REACT_APP_API_ENDPOINT}/onboarding/check-visit/${user.email}`)
      .then((res) => res.json())
      .then((response) => {
        // alert(JSON.stringify(response))
        if (!response.hasVisited) {
          setShowModal(true);
        }
      })
  }, [user])

  var formatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",

    // These options are needed to round to whole numbers if that's what you want.
    //minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
    //maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
  });
  const [shouldShowDashboard, setShouldShowDashboard] = useState(false)

  // if (!isLoading && !hasOnboarded) {
  //   window.location.href = '/onboarding';
  //   return null;
  // }
  // if (true) {
  //   return <LoadingPage />
  // }
  return (
    <>
      <div className="w-full overflow-scroll h-screen pb-40">
        <Alert organizationId={organizationId} />

        <div className="justify-between items-center mx-auto">

          <h1 className="text-3xl font-bold mt-4 ml-12 dark:text-gray-200">Dashboard</h1>
          <h2 className="text-xl font-medium mt-4 ml-12 dark:text-gray-200">Welcome, {user.given_name}</h2>

        </div>
        <div className="w-full mt-8">
          <StatsCards />
        </div>

        <div className="w-full grid grid-cols-5">
          <div className="w-full mt-8 col-span-4">
            <h3 className="text-lg font-medium leading-6 text-gray-900 dark:text-white ml-12 mt-4 mb-4">Sales</h3>
            <Graph />
            <ul className="grid grid-cols-1 gap-6 sm:grid-cols-2 lg:grid-cols-3 px-12">
              <li className="col-span-1 divide-y divide-gray-200">
                <CardNetworkGraph showDescription={true} />
              </li>
              <li className="col-span-1 divide-y divide-gray-200">
                <ProcessorGraph />
              </li>
              <li className="col-span-1 divide-y divide-gray-200">
                <AuthGraph />
              </li>
            </ul>

          </div>

          <div className="w-full mt-8 col-span-1">
            <h3 className="text-lg font-medium leading-6 text-gray-900 dark:text-white mt-4 mb-4">Recent Transactions</h3>
            <Timeline />
          </div>
        </div>


      </div>

    </>
  );
}

export default Dashboard;
