import { useEffect, useState } from "react";
import Spinner from "./components/Spinner";
import { useOrganizationId } from "./hooks/useOrganizationId";

function Timeline(props) {
  const [transactions, setTransactions] = useState([]);
  const [offset, setOffset] = useState(0);
  const [limit, setLimit] = useState(props.initialLimit);
  const [loading, setLoading] = useState(true);
  const { organizationId } = useOrganizationId();
  useEffect(() => {
    if (organizationId) {
      try {
        fetch(
          `${process.env.REACT_APP_API_ENDPOINT}/transactions/v2/get-by-organization/${organizationId}?limit=${limit}&offset=${offset}`
        )
          .then((response) => response.json())
          .then((data) => {
            setTransactions(data.data);
            setLoading(false);
          });
      } catch (err) {
        console.error(err);
      }
    }
  }, [organizationId, limit, offset]);

  if (loading) {
    return <Spinner center />;
  }
  var formatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",

    // These options are needed to round to whole numbers if that's what you want.
    //minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
    //maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
  });
  return (
    <div className={props.showDescription ? "bg-white border border-gray-700 px-2 sm:px-4 rounded-xl dark:bg-gray-800 ml-12 w-1/3  mt-4 mb-24" : ""}>
      {props.showDescription ? <h1 className="text-2xl font-medium dark:text-gray-200 mt-4">Transaction History</h1> : <></>}
      <div className="ml-6">




        <ol className="relative border-l border-gray-200 dark:border-gray-700 mr-8 mt-12 ">
          {transactions.map((transaction) => (
            <li className="mb-10 ml-6">
              <span className="flex absolute -left-3 justify-center items-center w-6 h-6 bg-blue-200 rounded-full ring-8 ring-white dark:ring-gray-900 dark:bg-blue-900">
                <svg
                  className="w-3 h-3 text-blue-600 dark:text-blue-400"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fillRule="evenodd"
                    d="M6 2a1 1 0 00-1 1v1H4a2 2 0 00-2 2v10a2 2 0 002 2h12a2 2 0 002-2V6a2 2 0 00-2-2h-1V3a1 1 0 10-2 0v1H7V3a1 1 0 00-1-1zm0 5a1 1 0 000 2h8a1 1 0 100-2H6z"
                    clipRule="evenodd"
                  />
                </svg>
              </span>
              <h3 className="flex items-center mb-1 text-lg font-semibold text-gray-900 dark:text-white">
                {formatter.format(transaction.amount)} - {transaction.cardType}
                <span className="bg-blue-100 text-blue-800 text-sm font-medium mr-2 px-2.5 py-0.5 rounded dark:bg-blue-200 dark:text-blue-800 ml-3">
                  Success
                </span>
              </h3>
              <time className="block mb-2 text-sm font-normal leading-none text-gray-400 dark:text-gray-500">
                Processed on: {transaction.createdAt}
              </time>
              <p className="mb-4 text-base font-normal text-gray-500 dark:text-gray-400">
                Processed by: {transaction.processedGatewayName}
              </p>
              {/* <p className="mb-4 text-base font-normal text-gray-500 dark:text-gray-400">Processed by: {transaction.processedGatewayName}</p> */}
              <a
                href={`/transactions/${transaction.id}`}
                className="inline-flex items-center py-2 px-4 text-sm font-medium text-gray-900 bg-white rounded-lg border border-gray-200 hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-2 focus:ring-blue-700 focus:text-blue-700 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700"
              >
                More Info.
              </a>
            </li>
          ))}
        </ol>
      </div>
    </div>
  );
}

export default Timeline;
