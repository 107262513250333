import { DownloadIcon } from '@heroicons/react/outline';
import { PDFViewer, PDFDownloadLink } from '@react-pdf/renderer';
import Invoice from './Pdf';




const InvoiceTemplate = (props) => {

  return (
    <div
      class="text-white bg-gray-800 hover:bg-gray-900 focus:outline-none focus:ring-4 focus:ring-gray-300 font-medium rounded-lg text-sm px-5 py-2.5 mr-2 mb-2 dark:bg-gray-800 dark:hover:bg-gray-700 dark:focus:ring-gray-700 dark:border-gray-700"
    >
      {console.log('here', props.transactionId)}
      <PDFDownloadLink document={<Invoice transactionId={props.transactionId} />} fileName={`${props.transactionId}.pdf`}>
        <DownloadIcon className="w-5 h-5 inline mr-2" />
        {/* <svg className="w-5 h-5 inline mr-2" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M14 10l-2 1m0 0l-2-1m2 1v2.5M20 7l-2 1m2-1l-2-1m2 1v2.5M14 4l-2-1-2 1M4 7l2-1M4 7l2 1M4 7v2.5M12 21l-2-1m2 1l2-1m-2 1v-2.5M6 18l-2-1v-2.5M18 18l2-1v-2.5" /></svg> */}
        Download Invoice

        {({ blob, url, loading, error }) => (loading ? 'Loading document...' : 'Download now!')}
      </PDFDownloadLink>
    </div >
  );
}

export default InvoiceTemplate;