import { useState, useEffect } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import useSWR from "swr";
import { fetcher } from "../utils/fetcher";
import { useOrganizationId } from "./useOrganizationId";

export function useDashboardData() {
  const [orgId, setOrgId] = useState(null);
  const { organizationId } = useOrganizationId();
  const { user, isAuthenticated, isLoading, loginWithRedirect } = useAuth0();

  const { data: amountProcessed } = useSWR(
    () =>
      `${process.env.REACT_APP_API_ENDPOINT}/dashboard/v2/get-amount-processed/${organizationId}`,
    fetcher
  );

  const { data: averageTransaction } = useSWR(
    () =>
      `${process.env.REACT_APP_API_ENDPOINT}/dashboard/v2/get-average-transaction/${organizationId}`,
    fetcher
  );

  const { data: totalFee } = useSWR(
    () =>
      `${process.env.REACT_APP_API_ENDPOINT}/dashboard/v2/get-total-fee/${organizationId}`,
    fetcher
  );

  const { data: customersAdded } = useSWR(
    () =>
      `${process.env.REACT_APP_API_ENDPOINT}/dashboard/v2/get-customers/${organizationId}`,
    fetcher
  );

  const { data: authRate } = useSWR(
    () =>
      `${process.env.REACT_APP_API_ENDPOINT}/dashboard/v2/get-customers/${organizationId}`,
    fetcher
  );


  return {


    amountProcessed: amountProcessed?.data
      ? amountProcessed?.data
      : null,
    averageTransaction: averageTransaction?.data
      ? Math.round(averageTransaction?.data * 100) / 100
      : null,
    totalFee: totalFee?.data
      ? parseFloat(totalFee?.data).toFixed(2)
      : null,
    customersAdded: customersAdded?.data ? customersAdded?.data : null,
  };
}
