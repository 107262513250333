import { StyleSheet, Text, View } from '@react-pdf/renderer'
// import { DateTime } from 'luxon'

const styles = StyleSheet.create({
  container: {
    display: 'flex',
    flexDirection: 'row',
    fontSize: 12,
    paddingBottom: 16,
  },
  h1: {
    fontSize: 24,
    fonttWeight: 600,
  },
  h2: {
    fontSize: 20,
  },
  left: {
    flex: 1,
  },
  right: {
    // textAlign: 'right',
  },
})

function Header({ customer, transaction, data, formTypeName }) {
  // const formattedDate =
  // date && DateTime.fromISO(date).toLocaleString(DateTime.DATE_FULL)
  return (
    <View style={styles.container}>
      <View style={styles.left}>
        <Text style={{ ...styles.h1 }}>Invoice: {customer.company}</Text>
        <Text style={{ marginTop: 10 }}>{customer.firstName + " " + customer.lastName}</Text>
        <Text>{customer.street}</Text>
        <Text>{customer.town + ', ' + customer.state + ' ' + customer.zipCode}</Text>
        <Text>{customer.phoneNumber} </Text>
        <Text>{customer.email}</Text>
      </View>
      <View style={styles.right}>
        <Text style={styles.h2}>#{transaction.id ? transaction.id.substring(26) : ""}</Text>
        <Text>{new Date(transaction.createdAt).toLocaleDateString('en-us', { weekday: "long", year: "numeric", month: "short", day: "numeric" })}</Text>
      </View>
    </View>
  )
}

export default Header
